.large {
    width: 2rem;
    height: 2rem;
}

.medium {
    width: 1.5rem;
    height: 1.5rem;
}

.small {
    width: 1.3rem;
    height: 1.3rem;
}
 