.navBarLink{
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #F5F5F5 !important;
}

.navBarLinkButton{
    padding: 10px 20px;
    background: #1d1d22;
    color: #fff;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.navBarItem{
    margin-left: 1rem !important;
}